import React from "react";

const Compania = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-right pl-md-40 pl-10">
                <div className="tp-section-wrapper">
                  <h2
                    className="tp-section-tit mb-30"
                    style={{ fontWeight: 600, marginTop: "-10px", fontSize:'40px' }}
                  >
                    We support your innovation path from the very start.
                  </h2>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                  >
                    Whether through improving existing processes, implementing
                    new technologies, or expanding into new markets, our
                    approach focuses on maximizing the potential of any business
                    model. From our Innovation HUB, we drive creativity and
                    collaboration. At Thinkia, we foster both internal and
                    external ideas to develop innovative solutions that
                    transform industries. 
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    Through our Innovation HUB, we
                    participate in the funding of disruptive projects with the
                    potential to revolutionize the market. By combining
                    resources, knowledge, and a strategic vision, we help bring
                    these ideas from conception to design, implementation, and
                    market deployment, ensuring a tangible and lasting impact
                    over time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end pb-100">
                  <img
                    src="assets/img/about/launch.jpg"
                    alt="despega"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default Compania;
