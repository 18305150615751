import React from "react";

const Juntos = () => {
  return (
    <>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay team_banner"
        style={{
          backgroundImage: `url("assets/img/about/juntos.jpg")`,
          position: "relative",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <p
                  className="text-white"
                  style={{ fontWeight: 400, fontSize: "22px" }}
                >
                  Let's talk!
                </p>
                <h3
                  className="text-white pb-4"
                  style={{
                    fontSize: "40px",
                    fontWeight: 500,
                  }}
                >
                  Let's start something great
                  <span style={{ color: "#00AFFF" }}> together </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Juntos;
