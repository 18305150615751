import React from "react";

const Presentes = () => {
  return (
    <>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay team_banner"
        style={{
          backgroundImage: `url("assets/img/about/mundo.jpg")`,
          position: "relative",
        }}
      >
        <div
          style={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(7, 8, 28, 0.8)",
            zIndex: 0,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3
                  className="text-white pb-4"
                  style={{ fontSize: "46px", fontWeight: 500 }}
                >
                  Present in more than 12 countries
                </h3>
                <div className="breadcrumb__list">
                  <span style={{color: "#00AFFF"}}>
                  SPAIN · PORTUGAL · EGIPT · FRANCE · HUNGARY 
                  </span>
                </div>
                <div className="breadcrumb__list">
                  <span style={{color: "#00AFFF"}}>
                   CZECH REPUBLIC · NETHERLANDS · MEXICO · CHILE · KAZAKHSTAN · COLOMBIA
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Presentes;
