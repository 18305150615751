import React from "react";

const QuienesSomos = () => {
  return (
    <div className="container pt-md-140 pt-40 pb-140" id="quienes-somos">
      <p
        style={{
          color: "#B5B5B5",
          fontWeight: 400,
          paddingBottom: "20px",
          fontSize: "22px",
        }}
      >
        About us
      </p>
      <div className="row">
        <div className="col-md-4 text-start text-md-start">
          {/* Primera columna */}
          <div>
            <h2 className="fw-bolder pb-4" style={{fontSize: '40px'}}>Our mission</h2>
            <p
              className="pr-md-80"
              style={{
                color: "#1A224C",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              At Thinkia, we specialize in delivering cutting-edge technology
              solutions that enable businesses to innovate, grow, and thrive in
              the digital age. <br />
              <br /> Our comprehensive portfolio of services addresses the most
              pressing challenges and leverages emerging technologies to
              generate measurable value and sustainable outcomes.
              <br />
              <br /> Below, we present our five key service areas and the
              capabilities we bring to each.
            </p>
          </div>
        </div>
        <div className="col-md-4 text-center px-5 pt-40">
          {/* Segunda columna */}
          <img src="assets/img/logo/raya.svg" alt="Imagen" />
        </div>
        <div className="col-md-4 d-md-flex flex-column justify-content-md-end text-start text-md-start pt-md-300 pt-100">
          {/* Tercera columna */}
          <div>
            <h2 className="fw-bolder pb-4">Our vision</h2>
            <p
              style={{
                color: "#1A224C",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "130%",
              }}
            >
              We are a team of more than 120 experts developing technology-based
              strategies. <br />
              <br />
              We strive every day to be recognized as pioneers in innovative
              solutions and leaders in implementing various technologies to
              enhance our clients' productivity and impact in the market.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuienesSomos;
