import React from "react";

const Lupa = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 p-0 m-0">
              <div className="tp-about-left p-relative">
                <div className="about-img h-100 w-100">
                  <img
                    src="assets/img/about/lupa.jpg"
                    alt="ojo"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 d-flex align-items-center"
              style={{ backgroundColor: "#06071B" }}
            >
              <div className="tp-about-right pl-20" style={{height: '100%'}}>
                <div className="tp-section-wrapper pt-20 pb-20  d-flex flex-column justify-content-evenly" style={{height: '100%'}}>
                  <div>
                    <h2
                      className="tp-section-tit mb-md-2 mb-10 mt-md-0 mt-40 text-white"
                      style={{ fontWeight: 500, fontSize: '30px' }}
                    >We lead strategic innovation with
                      <span style={{ color: "#00AFFF" }}> AI-driven </span> solutions
                    </h2>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        color: "#F9F9F9",
                      }}
                    >
                      We design and implement innovative technologies with the goal of meeting the current and future needs of the business.
                    </p>
                  </div>
                  <div>
                    <h2
                      className="tp-section-tit mb-md-2 mb-10 mt-md-0 mt-40 text-white"
                      style={{ fontWeight: 500, fontSize: '30px' }}
                    >
                      We 
                      <span style={{ color: "#00AFFF" }}> revolutionize </span>
                      traditional "consulting."
                    </h2>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        color: "#F9F9F9",
                      }}
                    >
                     We accompany our clients in whatever their needs may be, addressing every stage of the product lifecycle, from start to finish, ensuring success.
                    </p>
                  </div>
                  <div>
                    <h2
                      className="tp-section-tit mb-md-2 mb-10 mt-md-0 mt-40 text-white"
                      style={{ fontWeight: 500, fontSize: '30px' }}
                    >
                      We take your online sales to the 
                      <span style={{ color: "#00AFFF" }}> next level.</span> 
                    </h2>
                    <p
                      className="mb-60 mb-md-0"
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        color: "#F9F9F9",
                      }}
                    >
                      We are e-commerce experts, optimizing the comprehensive management of an online store. We help businesses ensure that the brand is consistently present at every customer touchpoint, from discovery to purchase.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about area end --> */}
    </>
  );
};

export default Lupa;
