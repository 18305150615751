import React from "react";

const Futuro = () => {
  return (
    <>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay team_banner"
        style={{
          backgroundImage: `url("assets/img/about/mundo.jpg")`,
          position: "relative",
          transform: "scaleY(-1)",
        }}
      >
        <div
          style={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(7, 8, 28, 0.8)",
            zIndex: 0,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3
                  className="text-white pb-4"
                  style={{
                    fontSize: "40px",
                    fontWeight: 500,
                    transform: "scaleY(-1)",
                  }}
                >
                  We drive the world towards a
                  <span style={{ color: "#00AFFF" }}> future</span> based on{" "}
                  <br />
                  technology & innovation.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Futuro;
