import React, { useEffect, useState } from "react";
import { EMAIL_TO_CONTACT } from "src/utilities/constants";

const Contact = () => {
  const [formContactData, setFormContactData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    to: EMAIL_TO_CONTACT,
  });
  const [messageResponse, setMessageResponse] = useState({
    status: null,
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormContactData({
      ...formContactData,
      [name]: value,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setMessageResponse({
        message: "",
        status: null,
      });
    }, 15000);
  }, [messageResponse]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(
        "https://sendemail-xaifmgak6q-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formContactData),
        }
      );

      if (response.ok) {
        setFormContactData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        setMessageResponse({
          message: "¡Enviado!",
          status: true,
        });
      } else {
        console.error("Error sending message", response);
      }
    } catch (error) {
      console.error("Error sending message", error);
      setMessageResponse({
        message: "¡No se pudo enviar tu mensaje!",
        status: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        id="contacto"
        className="tp-sv-contact grey-bg-4 pt-md-140 pt-100 pb-md-140 pb-80"
        style={{ backgroundColor: "#07081C" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center pb-md-40 pb-20">
                <h3
                  className="text-white pb-4"
                  style={{
                    fontSize: "40px",
                    fontWeight: 500,
                  }}
                >
                  Contact us
                </h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="tp-contact-form tp-contact-form-2 pl-50 pr-50 pb-md-55 pb-0">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name and Last Name*"
                        className="form-control mb-3"
                        value={formContactData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email*"
                        className="form-control mb-3"
                        value={formContactData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone number*"
                        className="form-control mb-3"
                        value={formContactData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="subject"
                        placeholder="Email Subject*"
                        className="form-control mb-3"
                        value={formContactData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        name="message"
                        placeholder="Message*"
                        className="form-control mb-3"
                        rows="5"
                        value={formContactData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    {messageResponse.message && (
                      <span
                        style={{
                          color: messageResponse.status ? "#ffff" : "#cc0000",
                        }}
                      >
                        {messageResponse.message}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="contact-button rounded-pill text-white py-2 px-4 mb-0"
                        id="nosotros"
                        style={{ backgroundColor: "#00AFFF", fontWeight: 500 }}
                        disabled={loading}
                      >
                        Submit →
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
