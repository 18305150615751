import React from "react";

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end mb-40 mb-md-0">
                  <img
                    src="assets/img/about/ojo.jpg"
                    style={{ borderRadius: "5px" }}
                    alt="ojo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right pl-md-40 pl-10">
                <div className="tp-section-wrapper">
                  <h2
                    className="tp-section-tit mb-30"
                    style={{ fontWeight: 600, marginTop: "-10px", fontSize: '42px' }}
                  >
                    The power of <span style={{ color: "#00AFFF" }}>AI</span>:
                    <br />
                    <span
                      className="tp-section-subtit"
                      style={{ fontSize: "35px", fontWeight: 500, fontSize: '36px' }}
                    >
                      The answer to the most demanding needs.
                    </span>
                  </h2>

                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                  >
                    As AI experts, our commitment goes beyond algorithms and
                    data—we strive to understand our clients' needs and deliver
                    intelligent, human-centered solutions. We are at the
                    forefront of innovation, pushing the boundaries of what’s
                    possible to transform the way the world works, lives, and
                    thrives. At Thinkia, we don’t just create technology; we
                    create a better future, where artificial intelligence is a
                    force for good.
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    Join us on this journey toward a future driven by artificial
                    intelligence, where every challenge is an opportunity and
                    every achievement is a step forward. Together, we can shape
                    a future where possibilities are limitless.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
