export const homeAndFormTexts = {
    formSection: {
        name: "Nombre *",
        phone: "Teléfono *",
        email: "Correo *",
        message: "Si tienes dudas escríbenos aquí tu consulta ...",
        rgpdText: "Acepto el tratamiento de datos y políticas de privacidad de Thinkia (Search Engine Business S.L.).*",
        informationButton: "Quiero más información"
    }
};
export const sectionTwoTexts = {
    mainSection: {
        title: {
            firstPart: "Aprende de la mano de destacados profesionales del sector, en activo y con contrastada experiencia docente.",
            secondPart: "Adquiere nuevos conocimientos y las habilidades que demandan las empresas."
        },
        stats: [
            {
                number: "10",
                label: "SEMANAS"
            },
            {
                number: "100",
                label: "HORAS"
            },
            {
                number: "8",
                label: "DOCENTES EXPERTOS"
            }
        ]
    },
    learnSection: {
        title: "¿Qué vas a conseguir?",
        benefits: [
            {
                text: "Familiarizarse con los marcos de trabajo ágiles más prevalentes en las principales empresas."
            },
            {
                text: "Analizar y comunicar las ventajas de incorporar una cultura ágil en proyectos u organizaciones.",
                
            },
            {
                text: "Elaborar estrategias y tácticas para productos o servicios, y priorizar los aspectos que generen mayor impacto."
            },
            {
                text: "Aplicar herramientas y técnicas prácticas basadas en escenarios reales de empresas, fundamentalmente digitales.",
                
            },
            {
                text: "Monitorizar las métricas ágiles para optimizar las operaciones diarias."
            },
            {
                text: "Implementar métodos para fortalecer, alinear y desarrollar las habilidades de los equipos."
            }
        ]
    }
};

export const accordionTexts = {
    title: "Contenido del Máster",
    modules: [
        {
            number: 'Módulo 01',
            title: 'Fundamentos de Agile',
            content: [
                {
                    description: 'Este módulo responde a las preguntas clave sobre quées ser ágil, su origen, y en qué contextos es más adecuado. Se explorarán las implicaciones de adoptar un enfoque ágil.',
                    list: [
                        '¿Qué es Agile?',
                        '¿Por qué Agile en IT?',
                        'Razones para el cambio',
                        'Comparación entre el modelo tradicional y el modelo ágil',
                        'Mentalidad Agile: Ser vs Hacer',
                        'Origen de Agile: El Manifiesto Ágil'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 02',
            title: 'Gestión de Producto',
            content: [
                {
                    description: 'Estrategia de producto, identificación de necesidades y oportunidades, y cómo abordarlas. Estudio de conceptos como user persona, Maslows needs y Product Market Fit, aplicados a ejemplos reales.',
                    list: [
                        'Planificación',
                        'Mapa de Customer Journey',
                        'Descubrimiento de Producto o Story Mapping',
                        'Comparación entre el modelo tradicional y el modelo ágil',
                        'Cómo crear una buena User Story',
                        'Priorización y gestión del Backlog'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 03',
            title: 'Scrum',
            content: [
                {
                    description: 'Aprende a trabajar con el marco ágil más popular mediante un caso práctico, adquiriendo las herramientas necesarias para aplicarlo de inmediato.',
                    list: [
                        'Gestión de equipos y productividad',
                        'Identificar roles o Liderazgo y motivación',
                        '¿Qué es Scrum? Conceptos',
                        'Comparación entre el modelo tradicional y el modelo ágil',
                        'Pilares y valores',
                        'Roles, artefactos y eventos',
                        'Gráficos y seguimiento'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 04',
            title: 'Escalado con SAFe',
            content: [
                {
                    description: ' Cuando múltiples equipos colaboran en el desarrollo de un producto, surgen desafíos de coordinación y dependencias. Aprende a gestionarlos eficazmente con SAFe.'
                }
            ]
        },
        {
            number: 'Módulo 05',
            title: 'Kanban',
            content: [
                {
                    description: 'Más que un tablero visual, Kanban es un método para definir, gestionar y mejorar la entrega de servicios. Este módulo enseña prácticas y herramientas para optimizar tu sistema.',
                    list: [
                        '¿Qué es Kanban?',
                        'Principios y valores',
                        'Las seis prácticas clave',
                        'Métricas: Cycle Time, Lead Time, Throughput',
                        'Caso práctico (STATIK)'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 06',
            title: 'Management 3.0',
            content: [
                {
                    description: 'Un nuevo enfoque de liderazgo y gestión basado en la responsabilidad colectiva. Descubre técnicas para mejorar la eficiencia de tu organización, promoviendo el bienestar del equipo.',
                    list: [
                        'Energizar a las personas',
                        'Empoderar a los equipos',
                        'Alinear restricciones',
                        'Desarrollar competencias',
                        'Aumentar la estructura',
                        'Mejorar continuamente',
                        'OKRs'
                    ]

                }
            ]
        },
        {
            number: 'Módulo 07',
            title: 'Proyecto Final',
            content: [
                {
                    description: 'Proyecto Final El Proyecto Final Máster (PFM) tiene como objetivo consolidar el foco en agile y poner en práctica los conocimientos adquiridos durante la formación. Se basará en un caso real o simulado y se estructurará en:',
                    list: [
                        'Contexto: Nombre del proyecto, descripción de la empresa y su sector, objetivo de transformación ágil de un área, servicio o proceso, definición de stakeholders.',
                        'Estrategia: Análisis del punto de partida, metas a alcanzar, definición de Objetivos SMART.',
                        'Ejecución: Elección del marco de trabajo o herramientas necesarias, descripción de su implementación en el proyecto y justificación de su uso.',
                    ]
                }
            ]
        }
    ]
};
export const targetAudienceTexts = {
    mainTitle: "¿A quién va dirigido?",
    prerequisites: {
        title: "Pre-requisitos:",
        description: "Está dirigido a perfiles de nivel medio que deseen avanzar en su carrera profesional en el ámbito tecnológico. Se requieren conocimientos básicos debmetodologías ágiles, con el objetivo de mejorar el rendimiento."
    },
    profiles: {
        title: "Orientado a perfiles como",
        list: [
            "Project Manager / Gestor de proyectos",
            "Freelance",
            "autónomo CEO | CTO | COO",
            "Emprendedores Product Manager",
            "Product Owner Scrum Master"
        ]
    },
    features: [
        {
            iconAlt: "Icono casa",
            text: "Desde Casa"
        },
        {
            iconAlt: "Icono Masterclass",
            text: "Masterclasses en directo"
        },
        {
            iconAlt: "Icono expertos",
            text: "Profesionales expertos en activo"
        }
    ]
};
export const upcomingSectionTexts = {
    mainTitle: "Próximas Convocatorias",
    edition: {
        items: [
            {
                text: "I Edición"
            },
            {
                text: "14 Marzo 2025"
            },
            {
                text: "Masterclass en directo\nViernes y Sábados"
            },
            {
                text: "Vie. 15:00 a 20:00h\nSáb. 9:00 a 14:00h"
            }
        ]
    },
    pricing: {
        label: "Precio",
        amount: "3.400€",
        buttonText: "Quiero más información"
    },
    details: {
        title: "Detalles del Máster",
        list: [
            "10 semanas - 100 horas",
            "8 docentes en activo",
            "Certificación al finalizar",
            "Masterclass en directo"
        ]
    }
};
export const faqSectionTexts = {
    title: "Preguntas Frecuentes",
    questions: [
        {
            question: "¿La formación es en directo?",
            answer: "Sí, hemos ideado nuestro Máster en Product Management como una formación completamente online pero sin perder la esencia de poder vivirla compartiendo la experiencia en directo con tus compañeros y profesores. Así pues, cada sesión es en directo para que puedas preguntar tus dudas a tus profesores y hablar con tus compañeros."
        },
        {
            question: "¿Cómo puedo acceder al Máster?",
            answer: "Es tan fácil como completar la solicitud con tus datos y nos pondremos rápidamente en contacto contigo. Agendaremos una entrevista para conocer tus intereses y ayudarte a alcanzar tu objetivo."
        },
        {
            question: "¿Qué requisitos hacen falta?",
            answer: "Partirás con una base si tienes conocimiento sobre producto, desarrollo, data, marketing o negocio, pero no son requerimientos obligatorios."
        },
        {
            question: "¿Por qué elegir este Máster y no otro?",
            answer: "En Thinkia Training hemos creado un máster 100% online con Masterclass en directo cada viernes y sábado en el que adquirás de la mano de profesionales en activo que tienen experiencia con este rol, y estarás en contacto con compañeros en un punto profesional similar al tuyo, y con las mismas inquietudes. Estamos totalmente comprometidos con tu formación, por eso hemos diseñado un programa adaptado a las necesidades actuales de las empresas con profesores que son profesionales de alto nivel en activo."
        },
        {
            question: "¿Lo puedo hacer mientras estoy trabajando?",
            answer: "El horario está adaptado al horario laboral para que puedas compaginar tu trabajo con tu formación. Además, al tratarse de una formación online puedes conectarte desde cualquier lugar a la hora de la Masterclass y pondremos a tu disposición la documentación que aporte cada profesor en nuestra plataforma formativa. Y si algún día no puedes conectarte las sesiones se grabarán para verlas luego. o poder repasar la clase con el experto."
        }
    ]
};

const textContextData = {
    homeAndFormTexts,
    sectionTwoTexts,
    accordionTexts,
    targetAudienceTexts,
    upcomingSectionTexts,
    faqSectionTexts
};

export default textContextData;