import React from "react";

export function Vertical({ image, title, desc }) {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "40px",
        textAlign: "left",
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
        maxWidth: "350px",
        margin: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          marginBottom: "20px",
          alignSelf: "center",
          position: "relative",
          width: "60px",
          height: "60px",
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <h3
        style={{
          color: "#1A224C",
          fontFamily: "Poppins",
          fontWeight: 600,
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        {title}
      </h3>
      <p
        style={{
          color: "#1A224C",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 400,
          marginBottom: "15px",
        }}
      >
        {desc.split("\n")[0]}
      </p>
      <p
        style={{
          color: "#1A224C",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        {desc.split("\n")[1]}
      </p>
    </div>
  );
}

const Services = () => {
  return (
    <div
      style={{
        backgroundColor: "#07081C",
        padding: "80px 30px",
        textAlign: "center",
      }}
    >
      <h2 style={{ color: "white", fontWeight: "lighter" }}>
        Service Lines{" "}
        <span style={{ color: "#00AFFF", fontWeight: 600 }}>Thinkia</span>
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "30px",
          marginTop: "50px",
        }}
      >
        <Vertical
          image="assets/img/icons/service1.svg"
          title="Digital Product & Experience Transformation"
          desc={`Focused on helping companies evolve and optimize their digital products, customer experience and user experience strategies, adapting to market demands and growing customer expectations.
            Digital Product & Experience Transformation solutions encompass innovation in the design, development, implementation and continuous improvement of digital products and services, with a user-centric approach that generates tangible business value.`}
        />

        <Vertical
          image="assets/img/icons/service2.svg"
          title="Data-Driven Strategy & Advanced Analytics"
          desc={`Empower businesses to make informed, strategic decisions through the effective use of data and advanced analytics.
By leveraging cutting-edge technologies and methodologies, data-driven strategy and advanced analytics enable organizations to uncover valuable insights, optimize operations, and drive innovation.`}
        />
        <Vertical
          image="assets/img/icons/service3.svg"
          title="Digital Engineering & SW Development"
          desc={`At Digital Engineering & Software Development, we transform ideas into innovative technology solutions. 
From software architecture to custom development, our team of experts drives digital evolution with agile methodologies, intelligent automation, and always integrating AI as a cross-cutting component in each solution proposal.`}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "30px",
          marginTop: "50px",
        }}
      >
        <Vertical
          image="assets/img/icons/service4.svg"
          title="Generative Intelligence & Innovation"
          desc={`Generative Intelligence leverages cutting-edge AI technologies, such as Generative Adversarial Networks (GANs) and Large Scale Language Models (LLMs), to create new ideas, content, and solutions. This service drives innovation by enabling companies to explore creative possibilities, optimize complex workflows, and develop unique, value-driven outcomes across diverse industries.
            Generative Intelligence and Innovation empower organizations to transform creativity and problem-solving, unlocking new opportunities and fostering growth in an increasingly competitive environment.`}
        />
        <Vertical
          image="assets/img/icons/service5.svg"
          title="Intelligent Automation"
          desc={`Intelligent Automation combines Artificial Intelligence (AI) and advanced automation technologies to optimize processes, improve efficiency, and drive innovation. This service enables businesses to automate complex workflows, reduce operational costs, and achieve superior results by integrating cognitive capabilities with traditional automation.
            With Intelligent Automation, companies can achieve operational excellence, improve their agility and innovate continuously, ensuring their competitiveness in a dynamic digital economy.`}
        />
      </div>
    </div>
  );
};

export default Services;
