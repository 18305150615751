import React from "react";
import "../../assets/css/thinkiaTraining.css";
import userandcomputer from "../../pages/LangingThinkia/assets/img/master`-product-management.png";
import Logo from "../../assets/img/logo/Group.png";

function ThinkiaTraining() {
  return (
    <section
      id="thinkia-training"
      style={{
        backgroundColor: "#07081C",
        color: "#fff",
        padding: "10rem 2rem",
      }}
    >
      <div className="container">
        {/* Cabecera */}
        <div className="text-center mb-5">
          <div className="d-flex flex-row justify-content-center align-items-center mb-2">
            <img
              src={Logo}
              alt="Logo thinkia"
              className="mb-3"
              style={{
                marginRight: "10px",
                maxHeight: "50px",
                objectFit: "contain",
              }}
            />
            <h1 className="thinkia-training-logo-text">Training</h1>
          </div>
          <h2 className="thinkia-training-title">
            We train IT professionals and AI specialists.
          </h2>
        </div>

        {/* Fila única: imagen en la izquierda y contenido en la derecha */}
        <div className="row align-items-stretch">
          {/* Columna izquierda: Imagen que se estira de arriba a abajo */}
          <div className="col-md-6 p-0">
            <img
              src={userandcomputer}
              alt="User and computer"
              style={{
                width: "90%",
                height: "90%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </div>

          {/* Columna derecha: Contenido dividido en 3 bloques */}
          <div className="col-md-6">
            {/* Bloque 1 */}
            <div className="mb-5">
              <h3 className="thinkia-training-description-title-inner">
                Specialized training for IT professionals in technology and work
                methodologies to become more effective.
              </h3>
              {/* <p className="thinkia-training-description-inner">
                Formación en metodologías de trabajo para ser más efectivos:
              </p> */}
              <ul className="thinkia-training-bullets-description">
                <li className="thinkia-training-description-inner">
                  <a
                    className="thinkia-training-bullets-links"
                    href="https://thinkia.com/training/master-devops"
                    alt="Máster DevOps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Master's in DevOps
                  </a>
                </li>
                <li className="thinkia-training-description-inner">
                  <a
                    className="thinkia-training-bullets-links"
                    href="https://thinkia.com/training/master-ciberseguridad"
                    alt="Máster en Ciberseguridad"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Master's in Ciberseguridad
                  </a>
                </li>
                <li className="thinkia-training-description-inner">
                  <a
                    className="thinkia-training-bullets-links"
                    href="https://thinkia.com/training/master-product-management"
                    alt="Máster Product Management"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Master's in Product Management
                  </a>
                </li>
                <li className="thinkia-training-description-inner">
                  <a
                    className="thinkia-training-bullets-links"
                    href="https://thinkia.com/training/master-agile-management"
                    alt="Máster Agile Management"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Master's in Agile Management
                  </a>
                </li>
              </ul>
              <p className="thinkia-training-description-inner mt-3">
                100 -120 hours of instruciton. Executive schedule ( Friday
                afternoons and Saturday morning).
              </p>
              <p
                className="thinkia-training-description-inner mt-3"
                style={{ color: "#00AFFF" }}
              >
                Elegible for funding through FUNDAE.
              </p>
            </div>

            {/* Bloque 2 */}
            <div className="mb-5">
              <h3 className="thinkia-training-description-title-inner">
                Custom Tech Training
              </h3>
              <p className="thinkia-training-description-inner mt-3">
                Programming languages, frameworks, architecture, and best
                practices.
              </p>
              <p className="thinkia-training-description-inner mt-1">
                We design tailored training programs adapted to company needs.
              </p>
            </div>

            {/* Bloque 3 */}
            <div>
              <h3 className="thinkia-training-description-title-inner">
                AI School
              </h3>
              <p className="thinkia-training-description-inner mt-3">
                To learn and become someone who builds AI.
              </p>
              <ul className="thinkia-training-bullets-description">
                <li className="thinkia-training-description-inner">
                  <a
                    className="thinkia-training-bullets-links"
                    href="https://thinkia.com/training/programa-avanzado-ia-generativa"
                    alt="Programa Avanzado Ingeniería en IA Generativa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Advanced Program in Generative AI Engineering
                  </a>
                </li>
                <li className="thinkia-training-description-inner">
                  <a
                    className="thinkia-training-bullets-links"
                    href="https://thinkia.com/training/programa-ingenierio-backend-python"
                    alt="Programa Ingeniero Backend Python"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Backend Engineering Python Program
                  </a>
                </li>
                <li className="thinkia-training-description-inner">
                  <a
                    className="thinkia-training-bullets-links"
                    href="https://thinkia.com/training/programa-ingeniero-data-science-python"
                    alt="Programa Ingeniero Backend Data Science Python"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Backend Engineer Data Science Python Program
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThinkiaTraining;
