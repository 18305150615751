import React from "react";

const Disenyamos = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row pb-60 pb-md-100">
            {/* Texto */}
            <div className="col-lg-6 order-lg-1">
              <div className="tp-about-right pl-10 pl-md-40">
                <div className="tp-section-wrapper mt-40 mt-md-0">
                  <h2
                    className="tp-section-tit mb-30"
                    style={{ fontWeight: 500, marginTop: "-10px", fontSize: '40px' }}
                  >
                    We design innovative solutions that optimize the customer
                    experience.
                  </h2>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                  >
                    From a Customer-Centric perspective, we develop end-to-end
                    strategic consulting solutions that address every phase of
                    the customer lifecycle.
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                  >
                    We implement the most innovative practices in the market,
                    which allow us to identify customer problems and needs,
                    tailor solutions to business requirements, and deploy new
                    experiences that create a significant impact on the bottom
                    line.
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    We use agile methodologies and digital strategies to
                    implement the latest technologies. This enables us to
                    develop any type of digital product or application, adapting
                    to your business's needs and opportunities.
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#1A224C",
                    }}
                    className="pt-10"
                  >
                    Our technology stack covers all market technologies, with a
                    focus on the most powerful ones.
                  </p>
                </div>
              </div>
            </div>

            {/* Imagen */}
            <div className="col-lg-6 order-lg-2">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  <img
                    src="assets/img/about/posit.jpg"
                    alt="posit"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- about about end--> */}
    </>
  );
};

export default Disenyamos;
