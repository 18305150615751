import React from "react";

const QueHacemos = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div
        className="tp-about-area pt-140"
        id="que-hacemos"
        style={{
          backgroundImage: `url("assets/img/about/ai.jpg")`,
          position: "relative",
          backgroundSize: "cover",
        }}
      >
        <div
          className="d-inline d-md-none"
          style={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(7, 8, 28, 0.8)",
            zIndex: 0,
          }}
        ></div>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right pl-40" >
                <div className="tp-section-wrapper">
                  <p
                    style={{
                      color: "#B5B5B5",
                      fontWeight: 400,
                      fontSize: "22px",
                    }}
                  >
                    What we do
                  </p>
                  <h2
                    className="tp-section-tit mb-30 text-white pb-120"
                    style={{ fontWeight: 500, marginTop: "30px", fontSize: '49px' }}
                  >
                    We revolutionize <br/> strategic consulting <br/> with advanced
                    <br />
                    <span style={{ color: "#00AFFF" }}>
                    artificial intelligence
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default QueHacemos;
